import styled from 'styled-components'

export const LightMainPayment = styled.section`
  color: #191919;
  max-width: 1224px;
  width: 100%;
  margin: 0 auto;
  padding: 50px 14.5px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 50px 20px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    padding: 50px 0;
  }
`

export const PaymentContent = styled.div`
  position: relative;
  background: linear-gradient(96.28deg, #f3f4f6 2.3%, #e2f9eb 99.99%);
  border-radius: 20px;
  padding: 26px 13px 23px 13px;
  overflow: hidden;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 35px 100px 12px 34px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    padding: 65px 125px 28px 56px;
  }
`
export const Header = styled.div`
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    flex-direction: row;
    justify-content: space-between;
  }
`
// Left Block
export const PaymentContentLeft = styled.div`
  position: relative;
  width: 100%;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    max-width: 371px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    max-width: 625px;
  }
`

export const PaymentCaption = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 122.5%;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.text4}
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.text1}
  }
`

export const PaymentHeading = styled.h2`
  ${({ theme }) => theme.mixins.H4}
  margin-top: 8px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    margin-top: 5px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.H2}
    margin-top: 6px;
  }
`

// Button
export const PaymentButtonWrapper = styled.div`
  width: 100%;
  margin-top: 32px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    margin-top: 30px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    margin-top: 70px;
  }
`

export const PaymentLink = styled.a`
  text-decoration: none;
  color: #191919;
  height: 45px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11;
  position: relative;
  font-weight: 400;
  line-height: 120%;
  background: transparent;
  border: 1px solid #191919;
  width: 100%;
  transition: 0.3s;

  &:hover {
    background-color: #fff;
    color: #18a866;
    border: 1px solid #18a866;
  }
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    max-width: 152px;
    font-size: 9.51363px;
    line-height: 12px;
    height: 28px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    max-width: 290px;
    ${({ theme }) => theme.mixins.text2}
    height: 45px;
  }
`

// Right Block

export const PaymentBankList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin-top: 40px;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    margin-top: 0;
    gap: 17px;
    max-width: 206px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    gap: 20px;
    max-width: 346px;
  }
`

export const PaymentBankListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.78);
  backdrop-filter: blur(6px);
  border-radius: 9px;
  height: 66px;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    height: 50px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    height: 83px;
  }
`
export const ListItemImage = styled.div<{ index: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    width: ${({ index }) =>
      index === 'tinkoff' ? '105px' : index === 'split' ? '112px' : '112px'};
    height: ${({ index }) =>
      index === 'tinkoff' ? '26px' : index === 'split' ? '23px' : '32px'};
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    width: ${({ index }) =>
      index === 'tinkoff' ? '177px' : index === 'split' ? '188px' : '188px'};
    height: ${({ index }) =>
      index === 'tinkoff' ? '45px' : index === 'split' ? '38px' : '53px'};
  }
`
export const Bottom = styled.div`
  display: flex;
  margin-top: 23px;
  ${({ theme }) => theme.mixins.text4}

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    margin-top: 9px;
    ${({ theme }) => theme.mixins.text5}
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    margin-top: 20px;
    ${({ theme }) => theme.mixins.text3}
  }
`
export const BottomComment = styled.p`
  margin-left: auto;
  z-index: 5;
  @media ${({ theme }) => theme.devices.noteUp} {
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    padding-right: 40px;
  }
`

export const Background = styled.div`
  position: absolute;
  bottom: -15%;
  right: -5%;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    width: 189px;
    height: 183px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    width: 318px;
    height: 308px;
  }
`
