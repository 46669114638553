import * as Styled from './styled'
import Image from 'next/image'
import getConfig from 'next/config'
import Link from 'next/link'
import handleViewport, { type InjectedViewportProps } from 'react-in-viewport'
import React from 'react'

const {
  publicRuntimeConfig: { STATIC_ASSETS_COMMON_URL },
} = getConfig()

export const LightMainPayment = () => {
  return (
    <Styled.LightMainPayment>
      <Styled.PaymentContent>
        <Styled.Background>
          <Image
            alt='bg'
            width={318}
            height={308}
            src={`${STATIC_ASSETS_COMMON_URL}/assets/images/light-main-intro-payment-percent.png`}
          />
        </Styled.Background>
        <Styled.Header>
          <Styled.PaymentContentLeft>
            <Styled.PaymentCaption>
              Xотите учиться, но не готовы платить сразу?
            </Styled.PaymentCaption>
            <Styled.PaymentHeading>
              Оформляйте курс в рассрочку от наших партнеров. Без переплат и
              первоначального взноса
            </Styled.PaymentHeading>
            <Styled.PaymentButtonWrapper>
              <Link href='/purchase/installment' passHref>
                <Styled.PaymentLink>Подробнее</Styled.PaymentLink>
              </Link>
            </Styled.PaymentButtonWrapper>
          </Styled.PaymentContentLeft>

          <Styled.PaymentBankList>
            <Styled.PaymentBankListItem>
              <Styled.ListItemImage index='tinkoff'>
                <Image
                  alt='tinkoff'
                  width={177}
                  height={45}
                  src={`${STATIC_ASSETS_COMMON_URL}/assets/images/light-main-intro-payment-tinkoff.png`}
                />
              </Styled.ListItemImage>
            </Styled.PaymentBankListItem>
            <Styled.PaymentBankListItem>
              <Styled.ListItemImage index='split'>
                <Image
                  alt='split'
                  width={188}
                  height={38}
                  src={`${STATIC_ASSETS_COMMON_URL}/assets/images/light-main-intro-payment-split.png`}
                />
              </Styled.ListItemImage>
            </Styled.PaymentBankListItem>
            <Styled.PaymentBankListItem>
              <Styled.ListItemImage index='sber'>
                <Image
                  alt='sber'
                  width={188}
                  height={53}
                  src={`${STATIC_ASSETS_COMMON_URL}/assets/images/light-main-intro-payment-sber.png`}
                />
              </Styled.ListItemImage>
            </Styled.PaymentBankListItem>
          </Styled.PaymentBankList>
        </Styled.Header>
        <Styled.Bottom>
          <Styled.BottomComment>
            *- условия рассрочки определяются банком
          </Styled.BottomComment>
        </Styled.Bottom>
      </Styled.PaymentContent>
    </Styled.LightMainPayment>
  )
}
export const LightMainPaymentViewport = (
  props: InjectedViewportProps<HTMLDivElement>,
) => {
  const { forwardedRef, enterCount } = props
  return (
    <div ref={forwardedRef}>{enterCount > 0 ? <LightMainPayment /> : null}</div>
  )
}

export default handleViewport(LightMainPaymentViewport)
